<template>
  <footer class="bg container-fluid py-5">
    <!--  <div><h2>visit us</h2></div>-->
    <div class="container py-3">
      <div class="row gap-3 gap-lg-0">
        <!--    card -->
        <div class="col-12 col-md-5 col-lg-3">
          <div class="card">
            <div class="card-title font-poppins fw-bold">
              Pharmacy
            </div>
            <div class="d-flex">
              <i class="fa-solid fa-location-pin pe-3 pt-4 fs-4"/>
              <div class="card-body font-rubik box">
                72/2552A, 
                Ground Floor, Mylaady Chambers, 
                Goshree Pottakuzhy Road, Pottakuzhy, Cross Road, 
                Kaloor, Kochi, Kerala 682017
              </div>
            </div>
          </div>
        </div>
        <!--    card end     -->
        <!--    card -->
       <!-- <div class="col-12 col-md-5 col-lg-3">
          <div class="card">
            <div class="card-title font-poppins fw-bold">
              Clinic
            </div>
            <div class="d-flex">
              <i class="fa-solid fa-house-chimney-medical pe-3 pt-3 fs-4"/>
              <div class="card-body font-rubik box">
                55/2765
                Mundukottakal Estates,
                Sahodaran Ayyappan Rd, Kadavanthra Junction,
                Kochi, Kerala 682020
                <div class="py-1">
                </div>
              </div>
            </div>
           <div class="px-5 fw-bold font-rubik">
             By Appointment Only
           </div>
          </div>
        </div> -->
        <!--    card end     -->
        <!--    card -->
        <div class="col-12 col-md-5 col-lg-3">
          <div class="card">
            <div class="card-title font-poppins fw-bold">
              Email us
            </div>
            <div class="d-flex">
              <i class="fa-solid fa-envelope pe-3 pt-3 fs-4"/>
              <div class="card-body font-rubik box">
                <a class="text-decoration-none text-black" href="mailto:// wellnessrxnaturals@gmail.com">
                  wellnessrxnaturals@gmail.com</a>
              </div>
            </div>
          </div>
        </div>
        <!--    card end     -->
        <!--    card -->
        <div class="col-12 col-md-5 col-lg-3">
          <div class="card">
            <div class="card-title font-poppins fw-bold">
              Call us
            </div>
            <div class="d-flex">
              <i class="fa-solid fa-phone pe-3 pt-4 fs-4"/>
              <div class="card-body font-rubik box">
                989 5321980
                <br>
                484 4041980
              </div>
            </div>
          </div>
        </div>
        <!--    card end     -->
        <h5 class="font-poppins small pt-3 px-0">Disclaimer: </h5>
        <div>
          The content on our website is not intended to replace advice from your doctor for diagnosis and treatment. The
          information provided here are sourced from published works on respective therapies.
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterBar"
}
</script>

<style scoped>
.bg {
  background-color: #D3D3D3;
  min-height: 300px;
}

.card {
  background: transparent !important;
  border: none !important;
  /*box-shadow: none !important;*/
}

.box {
  position: relative;
}

.box::before {
  height: 100%;
  width: 2px;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: #a19a9a;
}
</style>
