<template>
  <div class="bg position-relative">
    <span
        class="position-absolute display-5 fw-bold position-absolute bottom-0 start-0  px-5 py-5 font-georgia-italic head text-center">
      Welcome <br>to <br>Wellness Rx</span>
  </div>
  <section>
    <div class="container py-5 ">
      <div class="row gap-4 gap-md-0">
        <div class="col-12 col-md-6 d-flex justify-content-center align-items-center" data-aos="fade-right"
        >
          <img alt="image-2" class="rounded shadow-lg img-sz" src="@/assets/home-page-second.png">
        </div>
        <div class="col-12 col-md-6 " data-aos="fade-left">
          <h1 class="font-georgia-italic text-light pb-5">Be Well
            <span class="text-warning">With Homoeopathy</span>
          </h1>
          <div class="fw-normal font-rubik lead text-light">
            Wellness Rx is Nature’s Pharmacy and provides holistic therapeutics to integrate different therapies , that
            support the body’s natural healing system, the foremost and most popular among them being Homoeopathy.
            <div class="py-4"></div>
            At Wellness Rx, we are passionate about homoeopathy and our solutions are aligned to make you well at the
            earliest.
            <div class="py-4"></div>
            We look forward to being a part of your wellness journey.
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="bg-light py-5" data-aos="slide-up">
      <div class="container py-5 d-flex justify-content-center flex-column align-items-center">
        <h4 class="max-text position-relative font-rubik fw-normal text-center px-4 px-md-0">
          <span class="position-absolute top-0 start-0 fw-bolder font-rubik">"</span>Homoeopathy is the <span
            class="text-success fw-bold">safest</span> and most
          <span class="text-success fw-bold">reliable approach to ailments</span> and has withstood the <span
            class="text-success fw-bold">assaults of established medical practice <span
            class="position-absolute bottom-0 end-0 fw-bolder font-rubik">"</span>
      </span>for over 100 years.
        </h4>
        <div class="text-center lead fw-normal font-rubik py-3">
          - Yehudi Menuhin
        </div>
        <router-link :to="{name: 'Homoeopathy'}" class="btn btn-secondary fw-bold font-poppins">Explore Homoeopathy
        </router-link>
      </div>
    </div>
  </section>
  <section>
    <div class="bg-3 py-5">
      <div class="container">
        <div class="d-flex justify-content-center align-items-center pb-5">
          <h1 class="line-ug line-ug-green px-2 text-success">Our Products</h1>
        </div>
        <div class="container-fluid">
          <div class="row flex-row ">
            <div class="col-12 col-md-6" data-aos="fade-right">
              <ul class="fs-2 fw-normal font-poppins list-unstyled">
                <li class="py-4"><i><img alt="list_img" class="me-3" height="30" src="@/assets/list-image.png"></i>
                  Homoeopathic Medicines
                </li>
                <li class="py-4 px-lg-3 mx-lg-4"><i><img alt="list_img" class="me-3" height="30"
                                                         src="@/assets/list-image.png"></i>Bio Chemic Tissue Salts
                </li>
                <li class="py-4 px-lg-5 mx-lg-5"><i><img alt="list_img" class="me-3" height="30"
                                                         src="@/assets/list-image.png"></i>Bach Flower Remedies
                </li>
              </ul>
            </div>
            <div class="col-12 col-md-6" data-aos="slide-left">
              <ul class="fs-2 fw-normal font-poppins list-unstyled">
                <li class="py-4"><i><img alt="list_img" class="me-3" height="30" src="@/assets/list-image.png"></i>
                  Nutraceuticals
                </li>
                <li class="py-4 px-lg-3 mx-lg-4"><i><img alt="list_img" class="me-3" height="30"
                                                         src="@/assets/list-image.png"></i>Health Supplements
                </li>
                <li class="py-4 px-lg-5 mx-lg-5"><i><img alt="list_img" class="me-3" height="30"
                                                         src="@/assets/list-image.png"></i>Cosmetics
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section>
    <div class="container py-5">
      <div class="d-flex justify-content-center align-items-center pb-5">
        <h1 class="line-ug line-ug-white px-2 text-white">Our
          <yellow-text>Services</yellow-text>
        </h1>
      </div>
      <div class="container-fluid">
        <div class="row gap-4 gap-lg-0 row-flex d-lg-flex justify-content-center">
          <!-- card     -->
          <div class="col-12 col-lg-4" data-aos="fade-right">
            <div class="card p-3 px-3 pt-4 shadow-lg card-bg mh-card">
              <h3 class="card-title d-flex flex-wrap align-items-center fw-bold font-poppins pb-1">
                <!--              <i class="fa-solid fa-shield pe-3"/>-->
                Experience Pharmacy
              </h3>
              <div class="card-body position-relative">
                <div class="card-text lead fw-normal font-rubik pb-5 pb-lg-4">
                  Explore the wide range of complex homoeopathy medicines, nutraceuticals, health supplements and cosmetics on
                  display.
                </div>
                <router-link :to="{name: 'Pharmacy'}" class="btn btn-dark btn-md position-absolute bottom-0 left-0  ">Learn more...</router-link>
              </div>
            </div>
          </div>
          <!--      end-->
          <!-- card     -->
          <div class="col-12 col-lg-4" data-aos="slide-up">
            <div class="card p-3 pt-4 shadow-lg card-bg mh-card">
              <h3 class="card-title d-flex align-items-center fw-bold font-poppins pb-3">
                <!--              <i class="fa-solid fa-cart-shopping pe-3"/>-->
                Express Delivery
              </h3>

              <div class="card-body position-relative">
                <div class="card-text lead fw-normal font-rubik pb-5">
                  You can forward the prescriptions to us and we can arrange delivery directly to your location.
                </div>
                <router-link :to="{name: 'Delivery'}" class="btn btn-dark btn-md position-absolute bottom-0 left-0 ">Learn more...</router-link>
              </div>
            </div>
          </div>
          <!--      end-->
          <!-- card     -->
          <div class="col-12 col-lg-4" data-aos="slide-left">
            <div class="card p-3 pt-4 shadow-lg card-bg mh-card">
              <h3 class="card-title d-flex align-items-center fw-bold font-poppins pb-3">
                <!--              <i class="fa-solid fa-check-circle pe-3"/>-->
                Store Pickup
              </h3>

              <div class="card-body position-relative">
                <div class="card-text lead fw-normal font-rubik pb-5 pb-lg-3">
                  You can also order in advance and we can arrange for curb side pick up, during your routine trips to
                  the
                  city.
                </div>
                <router-link :to="{name: 'Delivery'}" class="btn btn-dark btn-md position-absolute bottom-0 left-0 ">Learn more...</router-link>
              </div>
            </div>
          </div>
          <!--      end-->
        </div>
      </div>
    </div>
  </section>
  <section>

  <div class="bg-light py-5 overflow-h" data-aos="slide-up">
    <div class="container py-5 d-flex justify-content-center flex-column align-items-center">
      <h4 class="max-text position-relative font-rubik fw-normal text-center px-4 px-md-0" data-aos="slide-right"
          data-aos-delay="30">
        <span class=" top-0 start-0 fw-bolder font-rubik">"</span>
        A piano turner has restored harmony to a piano;
        has added nothing and taken nothing from it,
        yet has restored it to harmony.
        A change that is unknown to one who does not
        think is visible to the internal eye.
        <span class=" top-0 start-0 fw-bolder font-rubik">"</span>
      </h4>
      <div class="text-center lead fw-normal font-rubik py-3">
        - J.T Kent
      </div>
      <router-link :to="{name: 'Consultation'}" class="btn btn-primary fw-bold font-poppins"> Request for Consultation
      </router-link>
    </div>
  </div>
  </section>
  <section>
    <div class="py-5 bg-l">
    <div class="container d-flex justify-content-center flex-column align-items-center " data-aos="fade-up">
      <h4 class="max-text-l position-relative font-poppins fw-normal text-center px-4 px-md-0 fs-3 lead">
        <span class="fw-bolder font-rubik">"</span>
        When there is beginning of <span class="text-success fw-bold">improvement</span>,
        the patient will demonstrate a greater
        <span class="text-success fw-bold">degree of comfort</span>, increasing <span class="text-success fw-bold">composure,freedom of spirit</span>,
        increased <span class="text-success fw-bold">courage - a
      </span>kind of <span class="text-success fw-bold">returning naturalness.</span>
        <span
            class="px-1 fw-bolder font-rubik">"</span>
      </h4>
      <h4 class="text-center pt-3">- Samuel Hahnemann</h4>
    </div>
  </div>
  </section>
</template>

<script>
// @ is an alias to /src
import YellowText from "@/components/Yellow";

export default {
  name: 'HomeView',
  components: {YellowText},
  mounted() {
  }
}
</script>

<style scoped>
.card {
  height: 100%;
}

.bg {
  background-image: url("@/assets/home-page-first.png");
  height: 650px;
  background-position: top center;
  background-size: cover;
}
.bg-3 {
  background-image: url("@/assets/home-page-third.png");
  /*height: 650px;*/
  background-position: bottom center;
  background-size: cover;
}

.bg-l {
  background-image: url("@/assets/home-page-last.jpg");
  height: 750px;
  background-position: top center;
  background-size: cover;
}

.head {
  text-shadow: 0 0 20px #00000040;
}

.card-bg {
  background-color: #ffffff;
}

.max-text {
  max-width: 500px;
}

.line-ug {
  position: relative;
}

.line-ug::before {
  position: absolute;
  content: '';
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 3px;
}

.line-ug-green::before {
  background: #0e5d33;
}

.line-ug-white::before {
  background: #D3D3D3;
}

.max-text-l {
  max-width: 700px;
}

.img-sz {
  height: 250px
}

@media screen and (min-width: 1000px) {
  .img-sz {
    height: 400px;
  }

  * {
    overflow: hidden;
  }
}


</style>
