<template>
  <div class="position-fixed top-0 start-0 h-content vw-100 bg rounded-bottom shadow-lg">
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <div class="navbar-brand ">
          <div class="d-flex flex-row justify-content-center align-items-center">
            <router-link to="/">
              <img alt="wellness.rx" height="50" src="@/assets/logo.png">
            </router-link>
            <div :class="nc" class="h2 ps-3 font-georgia fw-bold">
              Wellness Rx
              <div class="h5 mb-0 fw-lighter">
                Nature's Pharmacy
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <btn class="btn" @click="navClose">
            <i class="fa-solid fa-xmark fs-1"></i>
          </btn>
        </div>
      </div>
    </nav>
    <div class="container-fluid py-5">
      <div class="row gap- fs-4">
        <div class="col-12 border-top py-3 px-3">
          <router-link :to="{name: 'Home'}" class="text-decoration-none text-light d-flex justify-content-between align-items-center"
                       @click="navClose">
            Home <i class="fa-solid fa-angle-right fs-3 px-3"/></router-link>
        </div>
        <div class="col-12 border-top py-3 px-3">
          <router-link class="text-decoration-none text-light d-flex justify-content-between align-items-center" :to="{name: 'Delivery'}"
                       @click="navClose">
            Delivery <i class="fa-solid fa-angle-right fs-3 px-3"/></router-link>
        </div>
        <div class="col-12 border-top py-3 px-3">
          <router-link class="text-decoration-none text-light d-flex justify-content-between align-items-center" :to="{name: 'Pharmacy'}"
                       @click="navClose">
            Pharmacy <i class="fa-solid fa-angle-right fs-3 px-3"/></router-link>
        </div>
        <div class="col-12 border-top border-bottom py-3 px-3">
          <router-link class="text-decoration-none text-light d-flex justify-content-between align-items-center" :to="{name: 'Consultation'}"
                       @click="navClose">
            Consultation <i class="fa-solid fa-angle-right fs-3 px-3"/></router-link>
        </div>
        <div class="col-12 border-top py-3 px-3">
          <router-link class="text-decoration-none text-light d-flex justify-content-between align-items-center" :to="{name: 'Homoeopathy'}"
                       @click="navClose">
            Homoeopathy <i class="fa-solid fa-angle-right fs-3 px-3"/></router-link>
        </div>
        <div class="col-12 border-top py-3 px-3">
          <router-link class="text-decoration-none text-light d-flex justify-content-between align-items-center" :to="{name: 'ComplimentaryTherapies'}"
                       @click="navClose">
            Complimentary Therapies <i class="fa-solid fa-angle-right fs-3 px-3"/></router-link>

        </div>
        <!--<div class="col-12 border-top border-bottom py-3 px-3">
          <router-link class="text-decoration-none text-light d-flex justify-content-between align-items-center" :to="{name: 'ForDoctors'}"
                       @click="navClose">For
            Doctors <i class="fa-solid fa-angle-right fs-3 px-3"/></router-link>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import {navColor} from "@/util";

export default {
  name: "MobileNav",
  computed: {
    nc() {
      return navColor(this.$route.name)
    }
  },
  methods: {
    navClose() {
      this.$emit('close-menu')
    }
  },
  emits: ['close-menu']
}
</script>

<style scoped>
.w-60 {
  width: 60%;
}

.bg {
  background-color: #0e5d33 !important;
  box-shadow: 0 0 20px #00000030;
}
</style>
