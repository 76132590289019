<template>
  <nav-bar class="position-absolute top-0 start-0 w-100 z-index"/>
  <router-view class="p-10 "></router-view>
  <footer-bar/>
</template>

<style>
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.p-10{
  padding: 5.4rem 0 0 0 ;
}
.z-index{
  z-index: 99999;
}
body{
  background-color: #14532D !important;
  overflow-x:hidden;
}
.row-flex {
  display: flex;
  flex-wrap: wrap;
}
section{
  max-width: 100vw;
  overflow-x: hidden;
}
/*font faces*/
@font-face {
  font-family: 'Recoleta Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Recoleta Regular'), url('@/assets/fonts/Recoleta-RegularDEMO.woff') format('woff');
}
@font-face {
  font-family: 'georgia bold';
  font-style: normal;
  font-weight: bold;
  src: local('georgia bold'), url('@/assets/fonts/georgia bold.ttf') format('ttf');
}
@font-face {
  font-family: 'georgia bold italic';
  font-style: italic;
  font-weight: bold;
  src: local('georgia bold italic'), url('@/assets/fonts/georgia bold italic.ttf') format('ttf');
}
/* fonts */
.font-rubik{
  font-family: 'Rubik',"Lucida Sans";
}
.font-poppins{
  font-family: 'Poppins',serif;
}
.font-recoleta{
  font-family: "Recoleta Regular", Serif;
}
.font-georgia{
  font-family: "georgia bold", Serif;
}
.font-georgia-italic{
   font-family: "georgia bold italic", Serif;
 }
</style>
<script>
import AOS from 'aos'
import NavBar from "@/components/NavBar";
import FooterBar from "@/components/Footer";
export default {
  components: {FooterBar, NavBar},
  mounted() {
    AOS.init()
  }
}
</script>