<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <div class="navbar-brand">
        <div class="d-flex flex-row justify-content-center align-items-center">
          <router-link to="/">
            <img alt="wellness.rx" height="50" src="@/assets/logo.png">
          </router-link>
          <router-link to="/" class="text-decoration-none text-dark" :class="nc">
          <div class="h2 ps-3 font-recoleta fw-bolder">
            Wellness Rx
            <div class="h5 mb-0 fw-lighter ">
              Nature's Pharmacy
            </div>
          </div>
          </router-link>
        </div>
      </div>
      <button class="navbar-toggler" data-bs-toggle="collapse" type="button" @click="menu = true">
        <i class="fa-solid fa-bars fs-2"></i>
      </button>
      <transition name="fadeIn">
        <MobileNav v-if="menu" @close-menu="menu = false"></MobileNav>
      </transition>
      <div id="navscollapse" class="collapse navbar-collapse">
        <ul class="navbar-nav me-auto">
        </ul>
        <div class="d-flex">
          <ul class="navbar-nav me-auto font-rubik">
            <li class="nav-item px-3" >
              <router-link class="nav-links"  :to="{name: 'Home'}">Home</router-link>
            </li>
            <li class="nav-item px-3" >
              <router-link class="nav-links" :to="{name: 'Delivery'}">Delivery</router-link>
            </li>
            <li class="nav-item px-3">
              <router-link class="nav-links" :to="{name: 'Pharmacy'}">Pharmacy</router-link>
            </li>
            <li class="nav-item px-3">
              <router-link class="nav-links"  :to="{name: 'Consultation'}">Consultation</router-link>
            </li>

            <li class="nav-item px-3">
              <router-link class="nav-links" :to="{name: 'Homoeopathy'}">Homoeopathy</router-link>
            </li>
            <li class="nav-item px-3">
              <router-link class="nav-links"   :to="{name: 'ComplimentaryTherapies'}">Complimentary Therapies</router-link>
            </li>
            <!-- <li class="nav-item px-3">
              <router-link class="nav-links"  :to="{name: 'ForDoctors'}">For Doctors</router-link>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import MobileNav from "@/components/MobileNav";
export default {
  name: "NavBar",
  computed:{
  },
  data() {
    return {
      menu: false
    }
  },
  components: {MobileNav}
}
</script>

<style scoped>
.nav-links {
  text-decoration: none;
  color: black;
  position: relative;
  transition: color 200ms;
}

.nav-links::before {
  content: '';
  position: absolute;
  background: black;
  height: 2px;
  bottom: -3px;
  /*padding: -5px;*/
  left: 0;
  width: 0;
  transition: all 0.3s;
}

.nav-links:hover::before {
  width: 100%;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}


.fadeIn-enter-active,
.fadeIn-leave-active{
  transition: opacity 200ms;
}
.fadeIn-enter-from,
.fadeIn-leave-to{
  opacity: 0;
}
</style>