<template>
  <span class="text-warning fw-bold font-rubik"><slot></slot></span>
</template>

<script>
export default {
  name: "YellowText"
}
</script>

<style scoped>

</style>