import {createRouter, createWebHashHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomeView
    },
    {
        path: '/pharmacy',
        name: 'Pharmacy',
        component: () => import(/* webpackChunkName: "pharmacy" */ '../views/PharmacyView.vue')
    },
    {
        path: '/delivery',
        name: 'Delivery',
        component: () => import(/* webpackChunkName: "delivery" */ '../views/DeliveryView.vue')
    },
    {
        path: '/homoeopathy',
        name: 'Homoeopathy',
        component: () => import(/* webpackChunkName: "homoeo" */ '../views/HomoeopathyView.vue')
    },
    {
        path: '/complimentary-therapies',
        name: 'ComplimentaryTherapies',
        component: () => import(/* webpackChunkName: "complimentary" */ '../views/ComplimentaryTherapiesView.vue')
    },
    //{
      //  path: '/for-doctors',
        //name: 'ForDoctors',
        //component: () => import(/* webpackChunkName: "doctors" */ '../views/ForDoctorsView.vue')
    //}
    {
        path: '/consultation',
        name: 'Consultation',
        component: () => import(/* webpackChunkName: "consultation" */ '../views/ConsultationView.vue')
    },
    {path: '/:pathMatch(.*)*', component: HomeView},
]

const router = createRouter({
    history: createWebHashHistory(),
    // eslint-disable-next-line no-unused-vars
    scrollBehavior(to, from) {
        return {top: 0}
    },
    routes
})

export default router
